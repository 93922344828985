import Language from 'constants/Language';
import cx from 'classnames';

interface PanelButtonProps {
  label?: string;
  onClick?: () => void;
  variant: 'primary' | 'secondary';
  type: 'button' | 'submit';
  disabled?: boolean;
}

const PanelButton: React.FC<PanelButtonProps> = ({
  label,
  onClick,
  variant,
  type,
  disabled,
}) => {
  const defaultLabel =
    variant === 'primary'
      ? Language.auth.primaryButtonDefaultLabel
      : Language.auth.secondaryButtonDefaultLabel;
  const actualLabel = label ? label : defaultLabel;

  const panelButtonStyle = cx(
    'rounded-xs border pt-1 font-sans-serif text-sans-serif-30px md:text-sans-serif-50px font-light w-full uppercase',
    {
      'bg-black text-white': variant === 'primary',
      'bg-black border-black opacity-40 cursor-not-allowed':
        variant === 'primary' && disabled,
      'bg-white text-black': variant === 'secondary',
      'opacity-40 cursor-not-allowed': variant === 'secondary' && disabled,
    },
  );

  return (
    <button
      className={panelButtonStyle}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {actualLabel}
    </button>
  );
};

export default PanelButton;
