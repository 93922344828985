export const simulateAsync = (isSuccess: boolean, delay: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (isSuccess) {
        resolve('resolved successfully');
      } else {
        reject('did not resolve successfully');
      }
    }, delay);
  });
};
