import React from 'react';
import AuthPanel from './Common/AuthPanel';
import InlineInputErrorWrapper from './Common/InlineInputErrorWrapper';
import InlineError from './Common/InlineError';
import TextField from 'elements/TextField';
import PanelButton from './Common/PanelButton';
import { useFormik } from 'formik';
import Language from 'constants/Language';
import { sentenceSplit } from '../../utils/sentenceSplit';
import { simulateAsync } from '../../utils/simulateAsync';

interface GeneralAuthPanelProps {
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
  onScreen: boolean;
  setCurrentPronouns: (arg0: string) => void;
  setCurrentBirthdate: (arg0: string) => void;
}

export const GeneralAuthPanel: React.FC<GeneralAuthPanelProps> = ({
  primaryButtonAction,
  secondaryButtonAction,
  onScreen,
  setCurrentPronouns,
  setCurrentBirthdate,
}) => {
  const formik = useFormik({
    initialValues: {
      pronouns: '',
      birthdate: '',
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      if (!values.birthdate)
        errors.birthdate = Language.auth.errors.birthdateRequired;

      if (values.birthdate) {
        const birthdate = new Date(values.birthdate);
        const today = new Date();

        if (birthdate > today) {
          errors.birthdate = Language.auth.errors.invalidBirthdate;
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      setCurrentPronouns(values.pronouns);
      setCurrentBirthdate(values.birthdate);

      await simulateAsync(true, 250);

      primaryButtonAction();
    },
  });

  return (
    <AuthPanel onScreen={onScreen}>
      <form onSubmit={formik.handleSubmit}>
        <div className="px-2.5 md:px-5 pt-5 pb-18 md:pt-8 md:pb-24 w-full h-full max-h-screen overflow-y-auto">
          <p className="font-serif font-light text-serif-40px md:text-serif-75px inline-flex items-start flex-wrap w-full">
            <span className="mb-2 md:mb-4">
              {Language.auth.generalPanel.content[0]}&nbsp;
            </span>
            <InlineInputErrorWrapper>
              <TextField
                type="text"
                name="pronouns"
                placeholder={
                  Language.auth.generalPanel.pronounsInput.placeholder
                }
                ariaLabel={Language.auth.generalPanel.pronounsInput.ariaLabel}
                onChange={formik.handleChange}
                value={formik.values.pronouns}
                size="lg"
                className="w-full md:w-80 mb-2 md:mb-4"
                textFieldClassName="py-2.5 bg-light-gray border-dark-gray"
              />
            </InlineInputErrorWrapper>
            {sentenceSplit(Language.auth.generalPanel.content[1])}
            <InlineInputErrorWrapper>
              <TextField
                type="date"
                name="birthdate"
                placeholder={Language.auth.generalPanel.dateInput.placeholder}
                ariaLabel={Language.auth.generalPanel.dateInput.ariaLabel}
                onChange={formik.handleChange}
                value={formik.values.birthdate}
                size="lg"
                className="w-full md:w-80 mb-2 md:mb-4"
                error={formik.errors.birthdate}
                displayErrorString={false}
                textFieldClassName={'py-2.5 bg-light-gray border-dark-gray'}
              />
              {!!formik.errors.birthdate ? (
                <InlineError error={formik.errors.birthdate} />
              ) : null}
            </InlineInputErrorWrapper>
            <span className="mb-2 md:mb-4 hidden md:inline">.</span>
          </p>
        </div>
        <div className="absolute bottom-0 left-0 px-2.5 py-5 md:p-5 w-full grid grid-cols-2 gap-x-3 md:gap-x-14">
          <PanelButton
            variant="secondary"
            type="button"
            onClick={secondaryButtonAction}
          />
          <PanelButton
            variant="primary"
            type="submit"
            disabled={formik.values.birthdate === ''}
          />
        </div>
      </form>
    </AuthPanel>
  );
};
