import React, { useState } from 'react';
import AuthPanel from './Common/AuthPanel';
import InlineInputErrorWrapper from './Common/InlineInputErrorWrapper';
import InlineError from './Common/InlineError';
import TextField from 'elements/TextField';
import PanelButton from './Common/PanelButton';
import { simulateAsync } from '../../utils/simulateAsync';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import Language from 'constants/Language';
import { useAuth } from 'contexts/Auth';
import { sentenceSplit } from '../../utils/sentenceSplit';

interface ResetPasswordAuthPanelProps {}

export const ResetPasswordAuthPanel: React.FC<
  ResetPasswordAuthPanelProps
> = () => {
  const { updatePasswordAfterReset } = useAuth();

  const router = useRouter();
  const { id, token } = router.query;

  const [isLoading, setIsLoading] = useState(false);
  const [passwordReset, setPasswordReset] = useState<boolean | null>(null);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      if (!values.newPassword)
        errors.newPassword = Language.auth.errors.passwordRequired;

      if (values.newPassword.length < 8) {
        errors.newPassword = Language.auth.errors.passwordLength;
      }

      return errors;
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        setIsLoading(true);
        await updatePasswordAfterReset(
          id as string,
          values.newPassword,
          token as string,
        );

        setIsLoading(false);
        setPasswordReset(true);
      } catch (e: any) {
        setIsLoading(false);
        if (e.errors && e.errors.length > 0) {
          const errors: { [key: string]: string } = {
            newPassword: Language.auth.errors.generalUpdatePassword,
          };
          setErrors(errors);
        }
      }
    },
  });

  const goToSignIn = () => {
    setIsLoading(true);
    router.push('/auth');
  };

  const [viewPassword, setViewPassword] = useState(false);
  const toggleViewPassword = () => {
    setViewPassword((prev) => !prev);
  };

  return (
    <AuthPanel header={Language.auth.resetPasswordPanel.header}>
      <form onSubmit={formik.handleSubmit}>
        <div className="px-2.5 md:px-5 pt-5 pb-18 md:pt-8 md:pb-24 w-full h-full max-h-screen overflow-y-auto">
          <p
            aria-live="polite"
            className="font-serif font-light text-serif-40px md:text-serif-75px inline-flex items-start flex-wrap w-full"
          >
            <span className="mb-2 md:mb-4">
              {Language.auth.resetPasswordPanel.content}&nbsp;
            </span>
            <InlineInputErrorWrapper>
              <TextField
                type={!viewPassword ? 'password' : 'text'}
                name="newPassword"
                placeholder={
                  Language.auth.resetPasswordPanel.passwordInput.placeholder
                }
                ariaLabel={
                  Language.auth.resetPasswordPanel.passwordInput.ariaLabel
                }
                onChange={formik.handleChange}
                value={formik.values.newPassword}
                size="lg"
                className="w-full md:w-80 mb-2 md:mb-4"
                error={formik.errors.newPassword}
                displayErrorString={false}
                textFieldClassName={'py-2.5 bg-light-gray border-dark-gray'}
                icon="eye"
                iconPosition="right"
                iconAction={toggleViewPassword}
                iconAriaLabel={Language.auth.viewPasswordLabel}
              />
              {!!formik.errors.newPassword ? (
                <InlineError
                  longerMessage={true}
                  error={formik.errors.newPassword}
                />
              ) : null}
            </InlineInputErrorWrapper>
            <span className="mb-2 md:mb-4 hidden md:inline">.&nbsp;</span>
            {passwordReset && passwordReset === true
              ? sentenceSplit(
                  Language.auth.resetPasswordPanel.passwordResetSuccessMessage,
                )
              : null}
          </p>
        </div>
        <div className="absolute bottom-0 left-0 px-2.5 py-5 md:p-5 w-full">
          {passwordReset && passwordReset === true ? (
            <PanelButton
              variant="primary"
              type="button"
              label={
                isLoading
                  ? Language.auth.loadingLabel
                  : Language.auth.resetPasswordPanel.passwordResetButtonLabel
              }
              onClick={goToSignIn}
              disabled={false}
            />
          ) : (
            <PanelButton
              variant="primary"
              type="submit"
              label={
                isLoading
                  ? Language.auth.loadingLabel
                  : Language.auth.resetPasswordPanel.buttonLabel
              }
              disabled={formik.values.newPassword === '' || isLoading}
            />
          )}
        </div>
      </form>
    </AuthPanel>
  );
};
