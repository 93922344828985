import React, { useState } from 'react';
import AuthPanel from './Common/AuthPanel';
import PanelButton from './Common/PanelButton';
import { useFormik } from 'formik';
import Language from 'constants/Language';
import { useAuth } from 'contexts/Auth';
import { ProfileData } from 'lib/api/types';
import InlineError from './Common/InlineError';
import TextField from 'elements/TextField';

interface BioAuthPanelProps {
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
  onScreen: boolean;
  currentEmail: string;
  currentPassword: string;
  currentName: string;
  currentPronouns: string;
  currentBirthdate: string;
  currentHandle: string;
  activationId?: string;
  activationToken?: string;
}

export const BioAuthPanel: React.FC<BioAuthPanelProps> = ({
  primaryButtonAction,
  secondaryButtonAction,
  onScreen,
  currentEmail,
  currentPassword,
  currentName,
  currentPronouns,
  currentBirthdate,
  currentHandle,
  activationId,
  activationToken,
}) => {
  const { signUpUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      bio: '',
    },
    onSubmit: async (values, { setStatus }) => {
      try {
        setIsLoading(true);
        const signUpData: ProfileData = {
          name: currentName,
          pronouns: currentPronouns,
          birthdate: currentBirthdate,
          bio: values.bio,
          links: [],
        };

        if (!!activationId && !!activationToken) {
          await signUpUser(
            currentEmail,
            currentPassword,
            currentHandle,
            signUpData,
            activationId,
            activationToken,
          );
        } else {
          await signUpUser(
            currentEmail,
            currentPassword,
            currentHandle,
            signUpData,
          );
        }

        // clear saved email in local storage
        if (localStorage) localStorage.removeItem('email');

        setIsLoading(false);
        primaryButtonAction();
      } catch (e: any) {
        setIsLoading(false);
        if (e.errors && e.errors.length > 0) {
          setStatus(Language.auth.errors.generalSignUp);
        }
      }
    },
  });

  return (
    <AuthPanel onScreen={onScreen}>
      <form onSubmit={formik.handleSubmit}>
        <div className="px-2.5 md:px-5 pt-5 pb-18 md:pt-8 md:pb-24 w-full h-full max-h-screen overflow-y-auto">
          <p className="font-serif font-light text-serif-40px md:text-serif-75px mb-2 md:mb-4">
            {Language.auth.bioPanel.content}
          </p>
          <div className="w-full md:w-2/3 max-w-4xl flex flex-col">
            <TextField
              textarea
              resizable={false}
              name="bio"
              placeholder={Language.auth.bioPanel.bioTextarea.placeholder}
              ariaLabel={Language.auth.bioPanel.bioTextarea.ariaLabel}
              onChange={formik.handleChange}
              value={formik.values.bio}
              size="lg"
              className="w-full mb-2 md:mb-4"
              textFieldClassName="bg-light-gray border-dark-gray h-48"
            />
            {!!formik.status ? <InlineError error={formik.status} /> : null}
          </div>
        </div>
        <div className="absolute bottom-0 left-0 px-2.5 py-5 md:p-5 w-full grid grid-cols-2 gap-x-3 md:gap-x-14">
          <PanelButton
            variant="secondary"
            type="button"
            onClick={secondaryButtonAction}
          />
          <PanelButton
            variant="primary"
            type="submit"
            label={
              isLoading
                ? Language.auth.loadingLabel
                : Language.auth.bioPanel.primaryButtonLabel
            }
            disabled={isLoading}
          />
        </div>
      </form>
    </AuthPanel>
  );
};
