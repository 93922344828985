import { FocusOn } from 'react-focus-on';
import { useUI } from 'contexts/Ui';
import React from 'react';

interface AuthPanelProps {
  header?: string;
  children: React.ReactElement | null;
  onScreen?: boolean;
}

const AuthPanel: React.FC<AuthPanelProps> = ({
  header,
  children,
  onScreen = true,
}) => {
  const { cookieModal } = useUI();

  return (
    <FocusOn
      enabled={onScreen}
      returnFocus={false}
      autoFocus={false}
      shards={[cookieModal as React.RefObject<HTMLDivElement>]}
    >
      <div className="AuthPanel grid overflow-y-hidden h-screen max-h-screen-mobile w-screen relative">
        {header && (
          <h1 className="px-2.5 md:px-5 pt-5 md:pt-7 pb-4 font-serif font-light text-serif-40px md:text-serif-75px border-b">
            {header}
          </h1>
        )}
        {children}
      </div>
    </FocusOn>
  );
};

export default AuthPanel;
