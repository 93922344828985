import React, { useState } from 'react';
import AuthPanel from './Common/AuthPanel';
import InlineError from './Common/InlineError';
import InlineInputErrorWrapper from './Common/InlineInputErrorWrapper';
import TextField from 'elements/TextField';
import Button from 'elements/Button';
import PanelButton from './Common/PanelButton';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import Language from 'constants/Language';
import { useAuth } from 'contexts/Auth';
import { sentenceSplit } from '../../utils/sentenceSplit';
import { ServerError } from 'lib/api/types';

interface PasswordAuthPanelProps {
  secondaryButtonAction: () => void;
  onScreen: boolean;
  currentEmail: string;
}

type resetPasswordEmailSentState = 'sent' | 'error' | 'not sent';

export const PasswordAuthPanel: React.FC<PasswordAuthPanelProps> = ({
  secondaryButtonAction,
  onScreen,
  currentEmail,
}) => {
  const { signInUser, triggerPasswordReset } = useAuth();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordEmailSent, setResetPasswordEmailSent] =
    useState<resetPasswordEmailSentState>('not sent');

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      if (!values.currentPassword)
        errors.currentPassword = Language.auth.errors.passwordRequired;

      return errors;
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        setIsLoading(true);
        await signInUser(currentEmail, values.currentPassword);
        router.push('/profile');
      } catch (e: any) {
        setIsLoading(false);
        const errors: { [key: string]: string } = {};
        e.errors.forEach((error: ServerError) => {
          if (error.title === Language.errors.responses.unauthorized.title) {
            errors.currentPassword = Language.auth.errors.wrongPassword;
          } else {
            errors.currentPassword = Language.auth.errors.generalSignIn;
          }
        });
        setErrors(errors);
      }
    },
  });

  const clearFieldsAndSecondaryButtonAction = () => {
    secondaryButtonAction();
  };

  const resetPassword = async () => {
    try {
      const emailSent = await triggerPasswordReset(currentEmail);
      if (emailSent) setResetPasswordEmailSent('sent');
    } catch (e: any) {
      setResetPasswordEmailSent('error');
    }
  };

  const [viewPassword, setViewPassword] = useState(false);
  const toggleViewPassword = () => {
    setViewPassword((prev) => !prev);
  };

  return (
    <AuthPanel header={Language.auth.passwordPanel.header} onScreen={onScreen}>
      <form onSubmit={formik.handleSubmit}>
        <div className="px-2.5 md:px-5 pt-5 pb-18 md:pt-8 md:pb-24 w-full h-full max-h-screen overflow-y-auto flex flex-col">
          <p className="font-serif font-light text-serif-40px md:text-serif-75px inline-flex items-start flex-wrap w-full">
            <span className="mb-2 md:mb-4">
              {Language.auth.passwordPanel.content[0]}&nbsp;
            </span>
            <InlineInputErrorWrapper>
              <TextField
                type={!viewPassword ? 'password' : 'text'}
                name="currentPassword"
                placeholder={
                  Language.auth.passwordPanel.passwordInput.placeholder
                }
                ariaLabel={Language.auth.passwordPanel.passwordInput.ariaLabel}
                onChange={formik.handleChange}
                value={formik.values.currentPassword}
                size="lg"
                className="w-full md:w-80 mb-2 md:mb-4"
                error={formik.errors.currentPassword}
                displayErrorString={false}
                textFieldClassName={'py-2.5 bg-light-gray border-dark-gray'}
                icon="eye"
                iconPosition="right"
                iconAction={toggleViewPassword}
                iconAriaLabel={Language.auth.viewPasswordLabel}
                disabled={resetPasswordEmailSent === 'sent'}
              />
              {!!formik.errors.currentPassword ? (
                <InlineError error={formik.errors.currentPassword} />
              ) : null}
            </InlineInputErrorWrapper>
            <span className="mb-2 md:mb-4 hidden md:inline">.</span>
          </p>
          <p className="font-serif font-light text-serif-40px md:text-serif-75px inline-flex items-start flex-wrap w-full">
            {sentenceSplit(Language.auth.passwordPanel.content[1])}
            <span className="text-sans-serif-12px md:text-sans-serif-30px pt-1 hidden md:inline-block md:pt-2 mb-2 md:mb-4">
              <Button
                text={Language.auth.passwordPanel.resetPasswordButtonLabel}
                ariaLabel={Language.auth.passwordPanel.resetPasswordButtonLabel}
                size="md"
                onClick={resetPassword}
                variant="outlined"
                type="button"
                to=""
                className="uppercase"
              />
            </span>
            <span className="text-sans-serif-12px md:text-sans-serif-30px pt-1 md:hidden mb-2 md:mb-4">
              <Button
                text={Language.auth.passwordPanel.resetPasswordButtonLabel}
                ariaLabel={Language.auth.passwordPanel.resetPasswordButtonLabel}
                size="sm"
                onClick={resetPassword}
                variant="outlined"
                type="button"
                to=""
                className=""
              />
            </span>
          </p>
          {resetPasswordEmailSent !== 'not sent' && (
            <p
              role="alert"
              className="font-serif font-light text-serif-40px md:text-serif-75px inline-flex items-start flex-wrap w-full"
            >
              {resetPasswordEmailSent === 'sent' &&
                sentenceSplit(
                  Language.auth.passwordPanel.resetPasswordSuccessMessage({
                    email: currentEmail,
                  }),
                )}
              {resetPasswordEmailSent === 'error' && (
                <InlineError
                  error={Language.auth.errors.generalResetPassword}
                  notInline={true}
                />
              )}
            </p>
          )}
        </div>
        <div className="absolute bottom-0 left-0 px-2.5 py-5 md:p-5 w-full grid grid-cols-2 gap-x-3 md:gap-x-14">
          <PanelButton
            variant="secondary"
            type="button"
            onClick={clearFieldsAndSecondaryButtonAction}
          />
          <PanelButton
            variant="primary"
            type="submit"
            label={isLoading ? Language.auth.loadingLabel : undefined}
            disabled={formik.values.currentPassword === '' || isLoading}
          />
        </div>
      </form>
    </AuthPanel>
  );
};
