interface InlineInputErrorWrapperProps {
  children: React.ReactNode;
}

const InlineInputErrorWrapper: React.FC<InlineInputErrorWrapperProps> = ({
  children,
}) => {
  return (
    <div className="flex flex-col md:flex-row items-center md:space-x-2 mr-4 w-full md:w-auto mb-4 md:mb-0">
      {children}
    </div>
  );
};

export default InlineInputErrorWrapper;
