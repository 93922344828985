import Language from 'constants/Language';
import { ProfileField } from 'types';
import TextField from 'elements/TextField';
import { Avatar } from '../Avatar';

interface AvatarSectionProps extends ProfileField {
  isHeading?: boolean;
}

export const AvatarSection: React.FC<AvatarSectionProps> = ({
  isHeading = true,
  user,
  displayInputs = false,
  handleChange,
}) => {
  const { data: profileData, createdAt, handle } = user;

  const joinDate = createdAt ? new Date(createdAt) : null;

  const parsedJoinDate =
    joinDate &&
    joinDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

  if (displayInputs) {
    return (
      <div className="flex flex-col font-sans-serif">
        <div className="flex-shrink-0 mb-4 relative w-20 h-20">
          <Avatar {...user} />
        </div>
        <div className="w-full">
          {isHeading ? (
            <h1 className="font-light mb-2 md:text-sans-serif-30px">
              {profileData.name}
            </h1>
          ) : (
            <p className="font-light mb-2 text-sans-serif-30px">
              {profileData.name}
            </p>
          )}
          <TextField
            ariaLabel={Language.profile.avatarSection.handleField.ariaLabel}
            placeholder={Language.profile.avatarSection.handleField.placeholder}
            type="text"
            defaultValue={handle}
            onChange={handleChange}
            name="handle"
            required
            textFieldClassName="bg-light-gray border-dark-gray rounded-sm"
            className="w-full"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col font-sans-serif">
      <div className="flex-shrink-0 mb-4 relative w-20 h-20">
        <Avatar {...user} />
      </div>
      <div>
        {isHeading ? (
          <h1 className="font-light mb-2 text-sans-serif-30px">
            {profileData.name}
          </h1>
        ) : (
          <p className="font-light mb-2 text-sans-serif-30px">
            {profileData.name}
          </p>
        )}
        <p className="text-sans-serif-16px">
          {Language.profile.avatarSection.handle(handle)}
        </p>
        {parsedJoinDate && (
          <p className="uppercase text-sans-serif-12px font-light tracking-wide mt-2">
            {Language.profile.avatarSection.joinedOn({
              joinDate: parsedJoinDate,
            })}
          </p>
        )}
      </div>
    </div>
  );
};
