import { useState } from 'react';
import AuthPanel from './Common/AuthPanel';
import PanelButton from './Common/PanelButton';
import Language from 'constants/Language';
import { useRouter } from 'next/router';
import { useAuth } from 'contexts/Auth';
import { AvatarSection } from '../../components/Profile/Fields/AvatarSection';
import Icon from 'elements/Icon';

interface FinalAuthPanelProps {
  onScreen: boolean;
  currentName: string;
  currentHandle: string;
}

export const FinalAuthPanel: React.FC<FinalAuthPanelProps> = ({
  onScreen,
  currentName,
  currentHandle,
}) => {
  const router = useRouter();
  const { loadingUser, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const goToProfile = () => {
    setIsLoading(true);
    router.push(`/profile/${currentHandle}`);
  };

  return (
    <AuthPanel onScreen={onScreen}>
      <>
        <div className="px-2.5 md:px-5 pt-5 pb-18 md:pt-8 md:pb-24 w-full h-full max-h-screen overflow-y-auto relative z-10">
          <p className="font-serif font-light text-serif-40px md:text-serif-75px mb-4 flex flex-col">
            <span>
              {Language.auth.finalPanel.content.first({
                name: currentName,
              })}
            </span>
            <span>{Language.auth.finalPanel.content.second}</span>
          </p>
          {loadingUser || !user ? (
            <p>Loading user...</p>
          ) : (
            <AvatarSection user={user} isHeading={false} />
          )}
        </div>
        <div className="absolute z-10 bottom-0 left-0 px-2.5 py-5 md:p-5 w-full">
          <PanelButton
            variant="primary"
            type="submit"
            onClick={goToProfile}
            label={
              isLoading
                ? Language.auth.loadingLabel
                : Language.auth.finalPanel.primaryButtonLabel
            }
            disabled={isLoading}
          />
        </div>
        <div className="absolute top-1/2 left-1/2 w-1/2 sm:w-1/3 md:w-1/4 h-full z-0 flex content-center items-center transform -translate-x-1/2 -translate-y-1/2">
          <Icon icon="thumbsUp" className="w-full h-80" size="custom" />
        </div>
      </>
    </AuthPanel>
  );
};
