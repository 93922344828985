import React, { useState } from 'react';
import AuthPanel from './Common/AuthPanel';
import InlineInputErrorWrapper from './Common/InlineInputErrorWrapper';
import InlineError from './Common/InlineError';
import TextField from 'elements/TextField';
import PanelButton from './Common/PanelButton';
import { useFormik } from 'formik';
import Language from 'constants/Language';
import { useAuth } from 'contexts/Auth';
import { sentenceSplit } from '../../utils/sentenceSplit';
import { ServerError } from 'lib/api/types';

interface EmailAuthPanelProps {
  primaryButtonAction: (arg0: number) => void;
  secondaryButtonAction: () => void;
  onScreen: boolean;
  setCurrentEmail: (arg: string) => void;
}

export const EmailAuthPanel: React.FC<EmailAuthPanelProps> = ({
  primaryButtonAction,
  secondaryButtonAction,
  onScreen,
  setCurrentEmail,
}) => {
  const { testUserExists } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [activateEmailSent, setActivateEmailSent] = useState(false);
  const [userInShopifyDeclineState, setUserInShopifyDeclineState] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      if (!values.email) errors.email = Language.auth.errors.emailRequired;

      return errors;
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        setIsLoading(true);
        setCurrentEmail(values.email);
        const result = await testUserExists(values.email);
        setIsLoading(false);

        // if user exists state is 'shopify_activate' then save their email in storage
        // and show email prompt
        if (result === 'shopify_activate') {
          setActivateEmailSent(true);
          if (localStorage) localStorage.setItem('email', values.email);
        } else {
          // clear local storage of email key if not shopify activate state
          if (localStorage) localStorage.removeItem('email');
        }

        // if user exists state is 'shopify_decline' then show error message
        if (result === 'shopify_declined') {
          setUserInShopifyDeclineState(true);
        }

        // if user exists, go to password panel
        // if not, go to profile panel
        if (result === 'shopify' || result === 'local') {
          primaryButtonAction(1);
        } else if (result === 'none') {
          primaryButtonAction(2);
        }
      } catch (e: any) {
        setIsLoading(false);
        const errors: { [key: string]: string } = {};
        e.errors.forEach((error: ServerError) => {
          errors.email = error.detail;
        });
      }
    },
  });

  return (
    <AuthPanel header={Language.auth.emailPanel.header} onScreen={onScreen}>
      <form onSubmit={formik.handleSubmit}>
        <div className="px-2.5 md:px-5 pt-5 pb-18 md:pt-8 md:pb-24 w-full h-full max-h-screen overflow-y-auto">
          <p className="font-serif font-light text-serif-40px md:text-serif-75px inline-flex items-start flex-wrap w-full">
            <span className="mb-2 md:mb-4">
              {Language.auth.emailPanel.content}&nbsp;
            </span>
            <InlineInputErrorWrapper>
              <TextField
                type="email"
                name="email"
                placeholder={Language.auth.emailPanel.emailInput.placeholder}
                ariaLabel={Language.auth.emailPanel.emailInput.ariaLabel}
                onChange={formik.handleChange}
                value={formik.values.email}
                size="lg"
                className="w-full md:w-80 mb-2 md:mb-4"
                error={formik.errors.email}
                displayErrorString={false}
                textFieldClassName={'py-2.5 bg-light-gray border-dark-gray'}
              />
              {!!formik.errors.email ? (
                <InlineError error={formik.errors.email} />
              ) : null}
            </InlineInputErrorWrapper>
            <span className="mb-2 md:mb-4 hidden md:inline">.&nbsp;</span>
            {activateEmailSent
              ? sentenceSplit(
                  Language.auth.emailPanel.activateAccountEmailMessage,
                )
              : null}
            {userInShopifyDeclineState
              ? sentenceSplit(Language.auth.emailPanel.declinedStateMessage)
              : null}
          </p>
        </div>
        <div className="absolute bottom-0 left-0 px-2.5 py-5 md:p-5 w-full grid grid-cols-2 gap-x-3 md:gap-x-14">
          <PanelButton
            variant="secondary"
            type="button"
            disabled={true}
            onClick={secondaryButtonAction}
          />
          <PanelButton
            variant="primary"
            type="submit"
            label={isLoading ? Language.auth.loadingLabel : undefined}
            disabled={
              formik.values.email === '' ||
              isLoading ||
              activateEmailSent ||
              userInShopifyDeclineState
            }
          />
        </div>
      </form>
    </AuthPanel>
  );
};
