import React, { useState } from 'react';
import AuthPanel from './Common/AuthPanel';
import InlineError from './Common/InlineError';
import InlineInputErrorWrapper from './Common/InlineInputErrorWrapper';
import TextField from 'elements/TextField';
import PanelButton from './Common/PanelButton';
import { useFormik } from 'formik';
import Language from 'constants/Language';
import { sentenceSplit } from '../../utils/sentenceSplit';
import { simulateAsync } from '../../utils/simulateAsync';

interface ProfileAuthPanelProps {
  primaryButtonAction: () => void;
  secondaryButtonAction: (arg0: number) => void;
  onScreen: boolean;
  setCurrentName: (arg0: string) => void;
  setCurrentPassword: (arg0: string) => void;
  setCurrentHandle: (arg0: string) => void;
}

export const ProfileAuthPanel: React.FC<ProfileAuthPanelProps> = ({
  primaryButtonAction,
  secondaryButtonAction,
  onScreen,
  setCurrentName,
  setCurrentPassword,
  setCurrentHandle,
}) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      newPassword: '',
      handle: '',
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      if (!values.name) errors.name = Language.auth.errors.nameRequired;
      if (!values.newPassword)
        errors.name = Language.auth.errors.passwordRequired;
      if (!values.handle) errors.handle = Language.auth.errors.handleRequired;

      if (values.newPassword.length < 8) {
        errors.newPassword = Language.auth.errors.passwordLength;
      }

      return errors;
    },
    onSubmit: async (values) => {
      setCurrentName(values.name);
      setCurrentPassword(values.newPassword);
      setCurrentHandle(values.handle);

      await simulateAsync(true, 250);

      primaryButtonAction();
    },
  });

  const [viewPassword, setViewPassword] = useState(false);
  const toggleViewPassword = () => {
    setViewPassword((prev) => !prev);
  };

  return (
    <AuthPanel header={Language.auth.profilePanel.header} onScreen={onScreen}>
      <form onSubmit={formik.handleSubmit} className="h-full">
        <div className="px-2.5 md:px-5 pt-5 pb-18 md:pt-8 md:pb-24 w-full h-full max-h-screen overflow-y-auto">
          <p className="font-serif font-light text-serif-40px md:text-serif-75px inline-flex items-start flex-wrap w-full">
            {sentenceSplit(Language.auth.profilePanel.content[0])}
            <InlineInputErrorWrapper>
              <TextField
                type="text"
                name="name"
                placeholder={Language.auth.profilePanel.nameInput.placeholder}
                ariaLabel={Language.auth.profilePanel.nameInput.ariaLabel}
                onChange={formik.handleChange}
                value={formik.values.name}
                size="lg"
                className="w-full md:w-80 mb-2 md:mb-4"
                error={formik.errors.name}
                displayErrorString={false}
                textFieldClassName={'py-2.5 bg-light-gray border-dark-gray'}
              />
              {!!formik.errors.name ? (
                <InlineError error={formik.errors.name} />
              ) : null}
            </InlineInputErrorWrapper>
            {sentenceSplit(Language.auth.profilePanel.content[1])}
            <InlineInputErrorWrapper>
              <TextField
                type={!viewPassword ? 'password' : 'text'}
                name="newPassword"
                placeholder={
                  Language.auth.profilePanel.passwordInput.placeholder
                }
                ariaLabel={Language.auth.profilePanel.passwordInput.ariaLabel}
                onChange={formik.handleChange}
                value={formik.values.newPassword}
                size="lg"
                className="w-full md:w-80 mb-2 md:mb-4"
                error={formik.errors.newPassword}
                displayErrorString={false}
                textFieldClassName={'py-2.5 bg-light-gray border-dark-gray'}
                icon="eye"
                iconPosition="right"
                iconAction={toggleViewPassword}
                iconAriaLabel={Language.auth.viewPasswordLabel}
              />
              {!!formik.errors.newPassword ? (
                <InlineError error={formik.errors.newPassword} />
              ) : null}
            </InlineInputErrorWrapper>
            <span className="mb-2 md:mb-4 hidden md:inline">.&nbsp;</span>
            {sentenceSplit(Language.auth.profilePanel.content[2])}
            <InlineInputErrorWrapper>
              <TextField
                type="text"
                name="handle"
                placeholder={Language.auth.profilePanel.handleInput.placeholder}
                ariaLabel={Language.auth.profilePanel.handleInput.ariaLabel}
                onChange={formik.handleChange}
                value={formik.values.handle}
                size="lg"
                className="w-full md:w-80 mb-2 md:mb-4"
                error={formik.errors.handle}
                displayErrorString={false}
                textFieldClassName={'py-2.5 bg-light-gray border-dark-gray'}
              />
              {!!formik.errors.handle ? (
                <InlineError error={formik.errors.handle} />
              ) : null}
            </InlineInputErrorWrapper>
            <span className="mb-2 md:mb-4 hidden md:inline">.&nbsp;</span>
          </p>
        </div>
        <div className="absolute bottom-0 left-0 px-2.5 py-5 md:p-5 w-full grid grid-cols-2 gap-x-3 md:gap-x-14">
          <PanelButton
            variant="secondary"
            type="button"
            onClick={() => secondaryButtonAction(0)}
          />
          <PanelButton
            variant="primary"
            type="submit"
            disabled={
              formik.values.newPassword === '' ||
              formik.values.name === '' ||
              formik.values.handle === ''
            }
          />
        </div>
      </form>
    </AuthPanel>
  );
};
