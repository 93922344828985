import React, { HTMLAttributes, RefObject } from 'react';
import cx from 'classnames';
import { IconType } from './types';
import Icon from './Icon';
import Button from './Button';

type TextFieldElement = HTMLInputElement | HTMLTextAreaElement;

interface TextFieldProps {
  ariaLabel: string;
  autoComplete?: boolean;
  className?: string;
  textFieldClassName?: string;
  /**
   * If true, both the input and iconAction() will be disabled.
   */
  disabled?: boolean;
  error?: string;
  displayErrorString?: boolean;
  icon?: IconType;
  iconAction?: () => void;
  iconAriaLabel?: string;
  iconPosition?: 'left' | 'right';
  id?: string;
  innerRef?: RefObject<
    | (HTMLTextAreaElement & HTMLAttributes<HTMLTextAreaElement>)
    | (HTMLInputElement & HTMLAttributes<HTMLInputElement>)
  >;
  label?: string;
  name: string;
  onBlur?: (event: React.FocusEvent<TextFieldElement>) => void;
  onChange?: (event: React.ChangeEvent<TextFieldElement>) => void;
  onFocus?: (event: React.FocusEvent<TextFieldElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<TextFieldElement>) => void;
  placeholder?: string;
  required?: boolean;
  resizable?: boolean;
  size?: 'sm' | 'md' | 'lg';
  textarea?: boolean;
  type?:
    | 'email'
    | 'text'
    | 'tel'
    | 'number'
    | 'password'
    | 'textarea'
    | 'url'
    | 'date';
  pattern?: string;
  value?: string;
  defaultValue?: string;
}

const TextField: React.FC<TextFieldProps> = ({
  autoComplete = false,
  ariaLabel,
  className = '',
  textFieldClassName = '',
  disabled = false,
  error,
  displayErrorString = true,
  icon,
  iconAction,
  iconAriaLabel,
  iconPosition = 'right',
  id,
  innerRef,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  required = false,
  resizable = false,
  size = 'sm',
  textarea,
  type = 'text',
  pattern,
  value,
  defaultValue,
  ...rest
}) => {
  /**
   * When used with <InputElement />, this will return either <textarea />
   * or <input /> based on the `textarea` prop. This keeps the code more
   * DRY than using a ternary in the render method.
   */
  const InputElement = textarea ? 'textarea' : 'input';

  const _id = id || name;

  const inputClasses = cx(
    'rounded-xs font-sans-serif text-black placeholder:text-black placeholder:text-opacity-70 font-light p-2 border w-full appearance-none shadow-none outline-transparent bg-white transition-all ease-easy-ease-out',
    {
      'text-sans-serif-16px': size !== 'lg',
      'text-sans-serif-30px py-4': size === 'lg',
      'py-3': size === 'md',
    },
    icon &&
      size !== 'sm' && {
        'pl-11': iconPosition === 'left',
        'pr-11': iconPosition === 'right',
      },
    icon &&
      size === 'sm' && {
        'pl-8': iconPosition === 'left',
        'pr-8': iconPosition === 'right',
      },
    error ? 'border-red focus:outline-red' : 'border-black focus:outline-black',
    textarea && !resizable && 'resize-none',
    disabled && 'cursor-not-allowed border-dark-gray bg-light-gray',
    textFieldClassName && textFieldClassName,
  );

  const iconClasses = cx(
    'absolute inline-flex',
    textarea ? 'items-top top-2' : 'items-center h-fit-content',
    iconPosition === 'left' ? 'left-2' : 'right-2',
    !!iconAction && 'cursor-pointer',
    disabled && 'cursor-not-allowed',
  );

  let iconElement = null;
  if (icon) {
    if (iconAction && iconAriaLabel) {
      iconElement = (
        <Button
          leftIcon={
            <Icon icon={icon} size={size === 'sm' ? 'small' : 'medium'} />
          }
          onClick={iconAction}
          ariaLabel={iconAriaLabel}
          type="button"
          size="inherit"
          className={iconClasses}
          variant="nostyle"
        />
      );
    } else {
      iconElement = (
        <span aria-label={iconAriaLabel} className={iconClasses}>
          <Icon icon={icon} size={size === 'sm' ? 'small' : 'medium'} />
        </span>
      );
    }
  }

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={name}
          className="block mb-1 font-sans-serif text-sans-serif-12px uppercase tracking-wide"
        >
          {label}
        </label>
      )}

      <div className="relative flex items-center">
        <InputElement
          className={inputClasses}
          aria-label={ariaLabel}
          id={_id}
          name={name}
          onBlur={onBlur ? (e) => onBlur(e) : undefined}
          onFocus={onFocus ? (e) => onFocus(e) : undefined}
          onChange={onChange ? (e) => onChange(e) : undefined}
          onKeyDown={onKeyDown ? (e) => onKeyDown(e) : undefined}
          placeholder={placeholder}
          type={textarea ? 'textarea' : type}
          required={required}
          value={value}
          defaultValue={defaultValue}
          pattern={pattern}
          autoComplete={autoComplete ? 'on' : 'off'}
          ref={innerRef}
          disabled={disabled}
          {...rest}
        />
        {icon && iconElement}
      </div>
      {error && displayErrorString ? (
        <span className="block text-sans-serif-12px tracking-wide text-red mt-1">
          {error}
        </span>
      ) : null}
    </div>
  );
};

export default TextField;
