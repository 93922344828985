import React, { FC } from 'react';
import Head from 'next/head';
import Language from 'constants/Language';

interface Props {
  title?: string;
  description?: string;
}

const SEO: FC<Props> = ({
  title = Language.seo.title({ title: 'Main' }),
  description = Language.seo.description,
}) => {
  let origin: any;

  if (typeof window !== 'undefined') {
    origin = `https://${window.location.hostname}`;
  }

  const getCanonicalUrl = () => {
    if (!process.browser) return '';

    const pathname =
      window.location.pathname === '/' ? '' : window.location.pathname;

    return `${origin}${pathname}`;
  };

  return (
    <Head key={Language.seo.title({ title: title })}>
      <title>{Language.seo.title({ title: title })}</title>
      <link rel="icon" href="/images/favicon.png" />
      <meta name="description" content={description} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:image" content={Language.seo.shareCard} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="600" />
      <meta
        property="og:title"
        content={Language.seo.title({ title: title })}
      />
      <meta property="og:site_name" content={Language.seo.siteName} />
      <meta property="og:url" content={getCanonicalUrl()} />
      <meta property="og:description" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={Language.seo.title({ title: title })}
      />
      <meta name="twitter:image" content={Language.seo.shareCard} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content={Language.seo.handle} />
      <meta name="twitter:creator" content={Language.seo.handle} />
    </Head>
  );
};

export default SEO;
