interface InlineErrorProps {
  error: string;
  notInline?: boolean;
  longerMessage?: boolean;
}

const InlineError: React.FC<InlineErrorProps> = ({
  error,
  notInline = false,
  longerMessage = false,
}) => {
  return (
    <span
      role="alert"
      className={`h-fit-content ${
        notInline ? 'md:h-fit-content' : 'md:h-15'
      }  w-full ${
        longerMessage ? 'md:w-60' : 'md:w-56'
      } rounded-xs bg-red bg-opacity-10 text-red p-2.5 font-sans-serif text-sans-serif-16px mb-2 md:mb-4`}
    >
      {error}
    </span>
  );
};

export default InlineError;
